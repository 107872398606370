import React from 'react';
import { useEffect, useState } from 'react';
import './styles/Pages.css';
import foto from '../assets/foto.jpg';
import Professional from './Professional';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';



const Home = () => {
  const [activeSection, setActiveSection] = useState('cabecera');

  const handleScroll = () => {
    const sections = ['cabecera', 'sobre_mi', 'proyectos', 'contacto'];
    const offsets = sections.map(section => ({
      section,
      offset: document.getElementById(section).offsetTop,
    }));

    const scrollPosition = window.scrollY + window.innerHeight / 2;
    const currentSection = offsets.reduce((prev, curr) => (scrollPosition >= curr.offset ? curr.section : prev), 'cabecera');

    setActiveSection(currentSection);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='body2'>
      <div className='section-indicator'>
        <ul>
          <li>
            <a href="#cabecera" className={activeSection === 'cabecera' ? 'active' : ''}></a>
          </li>
          <li>
            <a href="#sobre_mi" className={activeSection === 'sobre_mi' ? 'active' : ''}></a>
          </li>
          <li>
            <a href="#proyectos" className={activeSection === 'proyectos' ? 'active' : ''}></a>
          </li>
          <li>
            <a href="#contacto" className={activeSection === 'contacto' ? 'active' : ''}></a>
          </li>
        </ul>
      </div>
      <div id="cabecera" className='cabecera'>
        <div className="effect-wrap">
          <div className="effect effect-1"></div>
          <div className="effect effect-2"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <div className="effect effect-3"></div>
          <div className="effect effect-4"></div>
          <div className="effect effect-5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <div className='effect effect-6'></div>
          <div className='effect effect-7'></div>
          <div className='effect effect-8'></div>
        </div>
        <div className='leftSection'>
          <div className='tituloName'>
            <h1>Noemi Cervera</h1>
          </div>
          <div>
            <Professional />
          </div>
          <button>
            <a href="#sobre_mi" className="btn2"><span className="spn2">Sobre mi !</span></a>
          </button>
        </div>
        <div className='rightSection'>
          <img src={foto} alt="fotografia" className='mi-foto' />
        </div>
      </div>
      <div id="sobre_mi" className='sobre_mi'>
        <About />
      </div>
      <div id="proyectos" className='proyectos'>
        <Projects />
      </div>
      <div id="contacto" className='contacto'>
        <Contact />
      </div>
    </div>
  );
};

export default Home;

{/* <div className="cover2">
                    <div id="bg-content" ref={bgContentRef}>
                        <section className="inner">    
                        </section>
                    </div>
                </div> */}